/*
 * (c) Copyright 2023, Panavion GmbH.
 */
import React from "react";
export var AuthenticationContext = React.createContext({
    authentication: undefined,
    /** Default implementation that just doesn't authenticate against anything. */
    login: function (username, password) {
        return Promise.reject({
            message: "Authentication context is not available."
        });
    }
});
export var useAuthenticationContext = function () {
    return React.useContext(AuthenticationContext);
};
