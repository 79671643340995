/*
 * (c) Copyright 2023, Panavion GmbH.
 */
import { DateTimeFormatter } from "@police-mobile/common-types";
/*
 *
 */
var Encoder = /** @class */ (function () {
    // ------------------------------------------ Constructors
    function Encoder(encoder) {
        if (typeof encoder === 'function') {
            this.encoder = encoder;
        }
        else {
            this.encoder = function (value) {
                return encoder.encode(value);
            };
        }
    }
    // ------------------------------------------ Public methods
    Encoder.prototype.encode = function (value) {
        return this.encoder(value);
    };
    // ------------------------------------------ Transformations
    Encoder.prototype.as = function () {
        return this.comap(function (value) { return value; });
    };
    Encoder.prototype.comap = function (f) {
        var _this = this;
        return new Encoder(function (value) {
            return _this.encoder(f(value));
        });
    };
    return Encoder;
}());
export { Encoder };
// ---------------------------------------------- Primitive encoders
/**
 * Encoder implementation for booleans.
 */
export var boolean = new Encoder(function (value) { return value; });
/**
 * Encoder implementation for numbers.
 */
export var number = new Encoder(function (value) { return value; });
/**
 * Encoder implementation for strings.
 */
export var string = new Encoder(function (value) { return value; });
/**
 * Encoder implementation for dates.
 */
export var date = new Encoder(function (date) { return date.format(DateTimeFormatter.ISO_LOCAL_DATE); });
/**
 * Encoder implementation for date times.
 */
export var dateTime = new Encoder(function (dateTime) { return dateTime.format(DateTimeFormatter.ISO_OFFSET_DATE_TIME); });
/**
 * Encoder implementation for UUIDs.
 */
export var uuid = new Encoder(function (uuid) { return uuid; });
// ---------------------------------------------- Compound encoders
export function array(encoder) {
    return new Encoder(function (values) {
        return values.map(function (value) { return encoder.encode(value); });
    });
}
export function optional(encoder) {
    return new Encoder(function (value) {
        if (value !== undefined && value !== null) {
            return encoder.encode(value);
        }
        else {
            return null;
        }
    });
}
export function object(schema, encode) {
    return new Encoder(function (value) {
        var result = {};
        var encoded = encode(value);
        Object.keys(schema).forEach(function (key) {
            result[key] = schema[key].encode(encoded[key]);
        });
        return result;
    });
}
// ---------------------------------------------- Factory methods
export function instance(encode) {
    return new Encoder(encode);
}
