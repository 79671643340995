import { AuthenticationCodec } from "../model/AuthenticationModel";
var storageKey = "authentication_model_key";
/**
 * Storage implementation for marshalling authentications to/from local storage.
 */
export var LocalAuthenticationStorage = {
    restore: function () {
        // Make sure that the browser supports local storage
        if (typeof localStorage === 'undefined') {
            return;
        }
        var jsonStr = localStorage.getItem(storageKey);
        if (jsonStr) {
            try {
                return AuthenticationCodec.decode(JSON.parse(jsonStr));
            }
            catch (e) {
                console.log("Failed to decode authentication model from local storage: ", e);
                return;
            }
        }
        else {
            return;
        }
    },
    store: function (authentication) {
        // Make sure that the browser supports local storage
        if (typeof localStorage === 'undefined') {
            return;
        }
        var jsonStr = JSON.stringify(AuthenticationCodec.encode(authentication));
        localStorage.setItem(storageKey, jsonStr);
    },
    reset: function () {
        // Make sure that the browser supports local storage
        if (typeof localStorage === 'undefined') {
            return;
        }
        localStorage.removeItem(storageKey);
    },
};
