import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { AlertTriangleIcon } from "../icons/streamline-regular/AlertTriangleIcon";
/**
 *
 */
export var MessageToast = function (_a) {
    var message = _a.message, onDismiss = _a.onDismiss;
    return (_jsxs("div", { className: clsx("flex gap-x-3 rounded-md pointer-events-auto p-4", {
            "bg-green-50 border-1 border-green-200": message.level === "success",
            "bg-amber-50 border-1 border-amber-200": message.level === "warning",
            "bg-red-100 border-1 border-red-200": message.level === "error"
        }), children: [_jsx(ToastIcon, { level: message.level }), _jsx("p", { className: clsx("text-sm font-medium", {
                    "text-green-800": message.level === "success",
                    "text-amber-800": message.level === "warning",
                    "text-red-700": message.level === "error"
                }), children: message.message }), onDismiss && _jsx("div", { className: "ml-auto -mr-1.5 -my-1.5", children: _jsx(DismissButton, { level: message.level, onClick: onDismiss }) })] }));
};
// ---------------------------------------------- Toast icon
var ToastIcon = function (_a) {
    var level = _a.level;
    return (_jsxs("div", { className: "flex-shrink-0", children: [level === "success" &&
                _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", className: "h-5 w-5 text-green-400", viewBox: "0 0 20 20", fill: "currentColor", "aria-hidden": "true", children: _jsx("path", { fillRule: "evenodd", d: "M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z", clipRule: "evenodd" }) }), level === "warning" &&
                _jsx(AlertTriangleIcon, { className: "h-5 w-5 text-amber-500", "aria-hidden": "true" }), level === "error" &&
                _jsx(XCircleIcon, { className: "h-5 w-5 text-red-400", "aria-hidden": "true" })] }));
};
var DismissButton = function (_a) {
    var level = _a.level, onClick = _a.onClick;
    return (_jsxs("button", { type: "button", onClick: onClick, className: clsx("inline-flex p-1.5 rounded-md focus:outline-none active-scale-99", {
            "bg-green-50 text-green-500 hover:bg-green-100": level === "success",
            "bg-amber-50 text-amber-500 hover:bg-amber-100": level === "warning",
            "bg-red-100 text-red-400 hover:bg-red-200": level === "error"
        }), children: [_jsx("span", { className: "sr-only", children: "Schlie\u00DFen" }), _jsx("svg", { className: "h-5 w-5", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", fill: "currentColor", "aria-hidden": "true", children: _jsx("path", { fillRule: "evenodd", d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z", clipRule: "evenodd" }) })] }));
};
