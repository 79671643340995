import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar } from "@police-mobile/common-components";
export var ContinueButton = function (_a) {
    var user = _a.user, onClick = _a.onClick;
    var fullName = determineFullName({
        firstName: user.firstName,
        lastName: user.lastName
    });
    return (_jsx("div", { className: "flex-shrink-0 flex bg-gray-700 hover:bg-gray-750 rounded-md active:scale-99 transition-colors", children: _jsx("a", { href: "#", onClick: function (ev) {
                ev.preventDefault();
                ev.stopPropagation();
                if (onClick) {
                    onClick();
                }
            }, className: "flex-shrink-0 w-full group px-4 py-3 block", children: _jsxs("div", { className: "flex items-center", children: [_jsx("div", { children: _jsx(Avatar, { firstName: user.firstName, lastName: user.lastName, size: "medium", weight: "normal", background: "bg-gray-500" }) }), _jsxs("div", { className: "ml-3", children: [_jsx("p", { className: "text-sm text-white", children: fullName }), _jsxs("p", { className: "text-xs text-gray-300 group-hover:text-gray-200", children: ["Als ", fullName, " fortfahren"] })] })] }) }) }));
};
// ---------------------------------------------- Utility functions
var determineFullName = function (_a) {
    var firstName = _a.firstName, lastName = _a.lastName;
    var result = [firstName, lastName]
        .filter(function (value) { return value !== undefined && value !== null; })
        .join(" ");
    return result.length > 0 ? result : "Unbekannt";
};
