import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { App } from "./App";
var container = document.getElementById("root");
if (container === null) {
    throw new Error("Cannot identify the root element for the application.");
}
var root = createRoot(container);
root.render(_jsx(React.StrictMode, { children: _jsx(App, {}) }));
