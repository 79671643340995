var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var LoadingIndicator = function (_a) {
    var _b = _a.className, className = _b === void 0 ? "" : _b, otherProps = __rest(_a, ["className"]);
    return (_jsxs("svg", __assign({ className: "animate-spin -ml-1 mr-3 ".concat(className), xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24" }, otherProps, { children: [_jsx("circle", { className: "opacity-25", cx: "12", cy: "12", r: "10", stroke: "currentColor", strokeWidth: "4" }), _jsx("path", { className: "opacity-75", fill: "currentColor", d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" })] })));
};
export var LoadingIndicatorDots = function (props) {
    /** The number of dots we'll display. */
    var numberOfDots = 10;
    /** The size of each individual dot. */
    var size = 7;
    function renderDot(index) {
        var offsetInSec = -1 + (index * (0.75 / (numberOfDots - 1)));
        return _jsx("rect", { x: (index * (160 / numberOfDots)) + 5, y: size, width: size, height: size, rx: size - 2, ry: size - 2, fill: 'currentColor', children: _jsx("animate", { attributeName: 'opacity', from: '1', to: '0', dur: '1s', begin: offsetInSec, repeatCount: 'indefinite' }) });
    }
    return (_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "160", height: "20", viewBox: "0 0 160 20", preserveAspectRatio: "xMidYMid" }, props, { children: [renderDot(0), renderDot(1), renderDot(2), renderDot(3), renderDot(4), renderDot(5), renderDot(6), renderDot(7), renderDot(8), renderDot(9)] })));
};
