/*
 * (c) Copyright 2023, Panavion GmbH.
 */
import { AuthenticationCodec } from "@police-mobile/common-auth";
import { post } from "@police-mobile/common-network";
/**
 * Provides username and password credentials to the middleware to authenticate the current user.
 * @param username username
 * @param password password
 */
export var login = function (_a) {
    var username = _a.username, password = _a.password;
    return post("/api/auth/login", AuthenticationCodec, {
        data: {
            username: username,
            password: password,
        }
    });
};
