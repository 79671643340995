import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuthenticationContext } from "../../../context/AuthenticationContext";
import clsx from "clsx";
/**
 * Access denied page that other services can redirect users to, to inform them about missing privileges.
 */
export var AccessDeniedPage = function () {
    var _a = useAuthenticationContext(), authentication = _a.authentication, login = _a.login;
    var user = authentication === null || authentication === void 0 ? void 0 : authentication.user;
    return (_jsx("div", { className: "min-h-screen bg-gray-800 flex justify-center sm:py-12 px-3 sm:px-6 lg:px-8", children: _jsx("div", { className: "mt-8 mb-8 mx-auto w-full max-w-md", children: _jsxs("div", { className: clsx("bg-gray-900 rounded-md px-4 sm:px-8 text-center", {
                    "pt-6 pb-7": user !== undefined,
                    "pt-6 pb-6": user === undefined
                }), children: [_jsx("p", { className: "text-sm text-gray-400", children: "403" }), _jsx("p", { className: "text-3xl text-gray-200", children: "Zugriff verweigert" }), _jsx("p", { className: "pt-6 text-sm text-gray-400", children: "Sie haben nicht die notwendige Berechtigung, um auf diese Ressource zuzugreifen. Bitte kontaktieren Sie das Support-Team, falls es sich hierbei um einen Fehler handeln sollte." })] }) }) }));
};
