import { jsx as _jsx } from "react/jsx-runtime";
import { MessageToast } from "../toast/MessageToast";
/**
 * Utility component to render an error message with an optional dismiss button.
 */
export var ErrorMessage = function (_a) {
    var message = _a.message, onDismiss = _a.onDismiss;
    if (message === null || message === undefined) {
        return null;
    }
    return _jsx(MessageToast, { message: {
            level: "error",
            message: message
        }, onDismiss: onDismiss });
};
