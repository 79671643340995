var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * (c) Copyright 2023, Panavion GmbH.
 */
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { ErrorMessage, LoadingIndicator } from "@police-mobile/common-components";
import { useAuthenticationContext } from "../../context/AuthenticationContext";
import { ContinueButton } from "./ContinueButton";
import { LoginField } from "./LoginField";
/**
 * Login page that asks users for their username and password.
 */
export var LoginPage = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var _a = useAuthenticationContext(), authentication = _a.authentication, login = _a.login;
    var user = authentication === null || authentication === void 0 ? void 0 : authentication.user;
    var _b = useState(""), username = _b[0], setUsername = _b[1];
    var _c = useState(""), password = _c[0], setPassword = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(null), error = _e[0], setError = _e[1];
    var buttonDisabled = username.length === 0 || password.length === 0;
    var onSubmit = function (ev) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            ev.preventDefault();
            ev.stopPropagation();
            setLoading(true);
            login(username, password).then(function (authentication) {
                setError(null);
                setLoading(false);
                var searchParams = new URLSearchParams(location.search);
                var target1 = searchParams.get("redirect");
                if (target1 && target1.length > 0) {
                    console.log("Successfully authenticated the user. Redirecting to ".concat(target1, "."), authentication);
                    window.open(target1, "_self");
                    return;
                }
                var target2 = searchParams.get("navigate");
                if (target2 && target2.length > 0) {
                    console.log("Successfully authenticated the user. Navigating to ".concat(target2, "."), authentication);
                    navigate(target2);
                    return;
                }
                console.log("Successfully authenticated the user. Navigating to root page.", authentication);
                navigate("/");
                return;
            }, function (error) {
                setError(error.message);
                setLoading(false);
            });
            return [2 /*return*/];
        });
    }); };
    var onContinue = function (user) {
        var searchParams = new URLSearchParams(location.search);
        var target1 = searchParams.get("redirect");
        if (target1 && target1.length > 0) {
            console.log("Continuing as authenticated user. Redirecting to ".concat(target1, "."), user);
            window.open(target1, "_self");
            return;
        }
        var target2 = searchParams.get("navigate");
        if (target2 && target2.length > 0) {
            console.log("Continuing as authenticated user. Navigating to ".concat(target2, "."), user);
            navigate(target2);
            return;
        }
        console.log("Continuing as authenticated user. Navigating to root page.", user);
        navigate("/");
    };
    return (_jsx("div", { className: "min-h-screen bg-gray-800 flex justify-center sm:py-12 px-3 sm:px-6 lg:px-8", children: _jsxs("div", { className: "mt-8 mb-8 mx-auto w-full max-w-md", children: [_jsxs("div", { className: clsx("bg-gray-900 rounded-md px-4 sm:px-8 shadow-2xl", {
                        "pt-6 pb-7": user !== undefined,
                        "pt-6 pb-6": user === undefined
                    }), children: [user && _jsx(ContinueButton, { user: user, onClick: function () { return onContinue(user); } }), user &&
                            _jsxs("div", { className: clsx("relative pb-5 pt-5"), children: [_jsx("div", { className: "absolute inset-0 flex items-center", children: _jsx("div", { className: "w-full border-t border-gray-400/20" }) }), _jsx("div", { className: "relative flex justify-center text-sm", children: _jsx("span", { className: "px-2 bg-gray-900 text-gray-400", children: "oder neu anmelden" }) })] }), _jsxs("form", { onSubmit: onSubmit, children: [_jsxs("div", { className: "space-y-5", children: [_jsx(LoginField, { id: "username", type: "text", label: "Benutzername", onChange: setUsername }), _jsx(LoginField, { id: "password", type: "password", label: "Kennwort", onChange: setPassword })] }), _jsx("div", { className: "mt-8", children: _jsxs("button", { type: "submit", disabled: buttonDisabled || loading, className: clsx("w-full flex justify-center py-2 px-4", "border border-transparent", "rounded-md shadow-sm text-sm", {
                                            "text-gray-300 bg-blue-400": buttonDisabled,
                                            "text-white bg-blue-700 hover:bg-blue-800 active:scale-99": !buttonDisabled
                                        }), children: [loading && _jsx(LoadingIndicator, { className: "w-5 h-5" }), "Anmelden"] }) })] })] }), typeof error === 'string' && _jsx("div", { className: "mt-8", children: _jsx(ErrorMessage, { message: error, onDismiss: function () { return setError(null); } }) })] }) }));
};
