/*
 * (c) Copyright 2023, Panavion GmbH.
 */
export var Either;
(function (Either) {
    function left(value) {
        return new Left(value);
    }
    Either.left = left;
    function right(value) {
        return new Right(value);
    }
    Either.right = right;
    /**
     * @param value
     * @param error
     */
    function fromNullable(value, error) {
        if (value === null || value === undefined) {
            return new Left(error);
        }
        return new Right(value);
    }
    Either.fromNullable = fromNullable;
})(Either || (Either = {}));
/**
 * The left side of the disjoint union, as opposed to the `Right` side.
 */
var Left = /** @class */ (function () {
    // ------------------------------------------ Constructors
    function Left(value) {
        this.value = value;
    }
    // ------------------------------------------ Either methods
    /**
     * The given function is not applied given that this is a Left.
     */
    Left.prototype.map = function (f) {
        // @ts-ignore
        return this;
    };
    Left.prototype.fold = function (onLeft, onRight) {
        return onLeft(this.value);
    };
    return Left;
}());
export { Left };
var Right = /** @class */ (function () {
    // ------------------------------------------ Constructors
    function Right(value) {
        this.value = value;
    }
    // ------------------------------------------ Either methods
    Right.prototype.map = function (f) {
        return new Right(f(this.value));
    };
    Right.prototype.fold = function (onLeft, onRight) {
        return onRight(this.value);
    };
    return Right;
}());
export { Right };
