var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/*
 * (c) Copyright 2023, Panavion GmbH.
 */
import { DateTimeFormatter, LocalDate, Month, OffsetDateTime, YearMonth, parseUuid } from "@police-mobile/common-types";
var Decoder = /** @class */ (function () {
    // ------------------------------------------ Constructors
    /**
     */
    function Decoder(decoder) {
        if (typeof decoder === 'function') {
            this.decoder = decoder;
        }
        else {
            this.decoder = function (json, context) {
                return decoder.decode(json, context);
            };
        }
    }
    // ------------------------------------------ Public methods
    Decoder.prototype.decode = function (json, context) {
        if (context === void 0) { context = []; }
        return this.decoder(json, context);
    };
    Decoder.prototype.as = function () {
        return this.map(function (value) { return value; });
    };
    /**
     * Allows you to transform the output of this decoder using the given function.
     * @param f Transforms the output of this decoder
     */
    Decoder.prototype.map = function (f) {
        var _this = this;
        return new Decoder(function (json, context) {
            return f(_this.decoder(json, context));
        });
    };
    /**
     * Allows you to transform the output of this decoder using the given function.
     * @param f Transforms the output of this decoder
     */
    Decoder.prototype.emap = function (f) {
        var _this = this;
        return new Decoder(function (json, context) {
            return f(_this.decoder(json, context)).fold(function (error) {
                throw new DecodingError(error, json, context);
            }, function (value) { return value; });
        });
    };
    /**
     * Allows you to specify a different decoder that should be used if we encounter any errors with this one.
     */
    Decoder.prototype.orElse = function (other) {
        var _this = this;
        return new Decoder(function (json, context) {
            try {
                return _this.decoder(json, context);
            }
            catch (e) {
                if (e instanceof DecodingError) {
                    return other.decode(json, context);
                }
                else if ('type' in e && e.type === 'decoding-error') {
                    return other.decode(json, context);
                }
                else {
                    throw e;
                }
            }
        });
    };
    Decoder.prototype.optional = function () {
        return optional(this);
    };
    return Decoder;
}());
export { Decoder };
/**
 * Represents an error that occurred while decoding JSON.
 */
var DecodingError = /** @class */ (function (_super) {
    __extends(DecodingError, _super);
    function DecodingError(message, json, context) {
        var _this = _super.call(this, message + " (".concat(context.join(""), ")")) || this;
        _this.type = 'decoding-error';
        return _this;
    }
    return DecodingError;
}(Error));
export { DecodingError };
// ---------------------------------------------- Value decoders
export var none = new Decoder(function (json, context) {
    return undefined;
});
/**
 * Decoder implementation for booleans.
 */
export var boolean = new Decoder(function (json, context) {
    if (typeof json !== 'boolean') {
        throw new DecodingError("Cannot decode '".concat(json, "' as a boolean."), json, context);
    }
    return json;
});
/**
 * Decoder implementation for numbers.
 */
export var number = new Decoder(function (json, context) {
    if (typeof json !== 'number') {
        throw new DecodingError("Cannot decode '".concat(json, "' as a number."), json, context);
    }
    return json;
});
/**
 * Decoder implementation for strings.
 */
export var string = new Decoder(function (json, context) {
    if (typeof json !== 'string') {
        throw new DecodingError("Cannot decode '".concat(json, "' as a string."), json, context);
    }
    return json;
});
/**
 * Decoder implementation for dates.
 */
export var date = new Decoder(function (json, context) {
    if (typeof json !== 'string') {
        throw new DecodingError("Cannot decode '".concat(json, "' as a date."), json, context);
    }
    try {
        return LocalDate.parse(json, DateTimeFormatter.ISO_LOCAL_DATE);
    }
    catch (_a) {
        throw new DecodingError("Cannot decode '".concat(json, "' as a date."), json, context);
    }
});
/**
 * Decoder implementation for date time values.
 */
export var dateTime = new Decoder(function (json, context) {
    if (typeof json !== 'string') {
        throw new DecodingError("Cannot decode '".concat(json, "' as a date time."), json, context);
    }
    try {
        return OffsetDateTime.parse(json, DateTimeFormatter.ISO_OFFSET_DATE_TIME);
    }
    catch (_a) {
        throw new DecodingError("Cannot decode '".concat(json, "' as a date time."), json, context);
    }
});
/**
 * Decoder implementation for months.
 */
export var month = new Decoder(function (json, context) {
    if (typeof json === 'string') {
        try {
            return Month.valueOf(json.toUpperCase());
        }
        catch (ex) {
            throw new DecodingError("Cannot decode '".concat(json, "' as a month."), json, context);
        }
    }
    else if (typeof json === 'number') {
        try {
            return Month.of(json);
        }
        catch (ex) {
            throw new DecodingError("Cannot decode '".concat(json, "' as a month."), json, context);
        }
    }
    else {
        throw new DecodingError("Cannot decode '".concat(json, "' as a month."), json, context);
    }
});
/**
 * Decoder implementation for year month values.
 */
export var yearMonth = new Decoder(function (json, context) {
    if (typeof json !== 'string') {
        throw new DecodingError("Cannot decode '".concat(json, "' as a year month."), json, context);
    }
    try {
        return YearMonth.parse(json);
    }
    catch (_a) {
        throw new DecodingError("Cannot decode '".concat(json, "' as a year month."), json, context);
    }
});
/**
 * Decoder implementation for UUIDs.
 */
export var uuid = new Decoder(function (json, context) {
    if (typeof json !== 'string') {
        throw new DecodingError("Cannot decode '".concat(json, "' as a UUID."), json, context);
    }
    try {
        return parseUuid(json);
    }
    catch (_a) {
        throw new DecodingError("Cannot decode '".concat(json, "' as a UUID."), json, context);
    }
});
// ---------------------------------------------- Compound decoders
/**
 * Decoder implementation for arrays of type `A`.
 * @param decoder The decoder for each array element
 * @param compareFn
 */
export function array(decoder, compareFn) {
    return new Decoder(function (json, context) {
        if (json === null || json === undefined) {
            return [];
        }
        if (!Array.isArray(json)) {
            throw new DecodingError("Cannot decode the value as an array.", json, context);
        }
        var result = json.map(function (value, index) {
            return decoder.decode(value, __spreadArray(__spreadArray([], context, true), ["[".concat(index, "]")], false));
        });
        if (compareFn) {
            result.sort(compareFn);
        }
        return result;
    });
}
export function optional(decoder) {
    return new Decoder(function (json, context) {
        if (json === undefined || json === null) {
            return null;
        }
        // Make sure that it's a non-empty string.
        if (typeof json === 'string' && json === '') {
            return null;
        }
        return decoder.decode(json, context);
    });
}
export function dictionary(decoder) {
    return new Decoder(function (json, context) {
        if (json === null || json === undefined) {
            return {};
        }
        if (typeof json !== 'object') {
            throw new DecodingError("Cannot decode the value as a dictionary.", json, context);
        }
        var result = {};
        Object.keys(json).forEach(function (key) {
            // Access the property from the raw JSON and decode it using the given decoder.
            result[key] = decoder.decode(json[key], __spreadArray(__spreadArray([], context, true), [".".concat(key)], false));
        });
        return result;
    });
}
export function object(schema, decode) {
    return new Decoder(function (json, context) {
        if (typeof json !== 'object') {
            throw new DecodingError("Cannot decode the value as an object.", json, context);
        }
        var result = {};
        Object.keys(schema).forEach(function (key) {
            // Access the property from the raw JSON and decode it using the decoder provided in the schema.
            result[key] = schema[key].decode(json[key], __spreadArray(__spreadArray([], context, true), [".".concat(key)], false));
        });
        try {
            return decode(result);
        }
        catch (e) {
            if (typeof e === 'string') {
                throw new DecodingError(e, json, context);
            }
            else {
                throw e;
            }
        }
    });
}
var DiscriminationDecoder = /** @class */ (function () {
    // ------------------------------------------ Constructors
    function DiscriminationDecoder(decoders) {
        if (decoders === void 0) { decoders = {}; }
        this.decoders = decoders;
    }
    // ------------------------------------------ Builder methods
    /**
     */
    DiscriminationDecoder.prototype.on = function (key, decoder) {
        var _this = this;
        // Create a new copy of the decoders that were registered already ..
        var result = {};
        Object.keys(this.decoders).forEach(function (key) {
            result[key] = _this.decoders[key];
        });
        // .. and then include the new decoder.
        result[key] = decoder;
        return new DiscriminationDecoder(result);
    };
    // ------------------------------------------ Decodable methods
    DiscriminationDecoder.prototype.decode = function (json, context) {
        if (typeof json !== 'object') {
            throw new DecodingError("Cannot decode the value as an object.", json, context);
        }
        var keys = Object.keys(json);
        if (keys.length === 1) {
            var key = keys[0];
            var decoder = this.decoders[key];
            if (decoder === undefined) {
                throw new DecodingError("Cannot determine which decoder to use. Unknown key '".concat(key, "'."), json, context);
            }
            return decoder.decode(json[key], __spreadArray(__spreadArray([], context, true), [".".concat(key)], false));
        }
        else if ('type' in json) {
            var key = json['type'];
            if (typeof key !== 'string') {
                throw new DecodingError("Cannot determine which decoder to use. Unknown key '".concat(key, "'."), json, context);
            }
            var decoder = this.decoders[key];
            if (decoder === undefined) {
                throw new DecodingError("Cannot determine which decoder to use. Unknown key '".concat(key, "'."), json, context);
            }
            return decoder.decode(json, context);
        }
        else {
            throw new DecodingError("Cannot determine which decoder to use. " +
                "The object has either multiple keys or none at all: ".concat(keys), json, context);
        }
    };
    return DiscriminationDecoder;
}());
export { DiscriminationDecoder };
// ---------------------------------------------- Utility methods
export { camelCase, snakeCase } from "./casing";
// ---------------------------------------------- Factory methods
export function identity(value) {
    return value;
}
export function instance(decode) {
    return new Decoder(decode);
}
