/*
 * (c) Copyright 2023, Panavion GmbH.
 */
import * as uuid from "uuid";
/**
 * Generates and returns a new random unique identifier.
 */
export function randomUuid() {
    return uuid.v4();
}
/**
 * @param str
 * @throws TypeError if the given string is not a UUID
 */
export function parseUuid(str) {
    return uuid.stringify(uuid.parse(str));
}
/**
 * Validates whether the given string is a UUID.
 */
export var validate = function (str) {
    return uuid.validate(str);
};
export var base64 = {
    /**
     * Encodes the given UUID in Base64 format (24 character long string).
     */
    encode: function (value) {
        var bytes = new Uint8Array(uuid.parse(value));
        // @ts-ignore
        var string = String.fromCharCode.apply(null, bytes);
        // noinspection JSDeprecatedSymbols
        return btoa(string);
    },
    decode: function (value) {
        // noinspection JSDeprecatedSymbols
        var string = atob(value);
        var bytes = [];
        for (var i = 0; i < string.length; i++) {
            bytes.push(string.charCodeAt(i));
        }
        return uuid.stringify(bytes);
    }
};
