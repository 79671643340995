import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import { UserIcon } from "@heroicons/react/24/solid";
/**
 *
 */
export var Avatar = function (_a) {
    var firstName = _a.firstName, lastName = _a.lastName, _b = _a.size, size = _b === void 0 ? "medium" : _b, _c = _a.weight, weight = _c === void 0 ? "bold" : _c, _d = _a.background, background = _d === void 0 ? "bg-gray-300" : _d;
    var initials = determineInitials(firstName, lastName);
    return (_jsxs("span", { className: clsx("inline-flex items-center justify-center rounded-full", background, {
            "h-8 w-8": size === "small",
            "h-10 w-10": size === "medium",
            "h-16 w-16": size === "large"
        }), children: [initials &&
                _jsx("span", { className: clsx("leading-none text-white", {
                        "text-sm": size === "small",
                        "text-md": size === "medium",
                        "text-2xl": size === "large",
                        "font-bold": weight === "bold"
                    }), children: initials }), !initials &&
                _jsx(UserIcon, { className: clsx("text-white", {
                        "h-5 w-5": size === "small",
                        "h-6 w-6": size === "medium",
                        "h-10 w-10": size === "large"
                    }) })] }));
};
// ---------------------------------------------- Utility functions
/**
 * Utility function that calculates the initials to display for a given name.
 */
var determineInitials = function (firstName, lastName) {
    var _a, _b;
    var first = (_a = firstName === null || firstName === void 0 ? void 0 : firstName.charAt(0)) !== null && _a !== void 0 ? _a : "";
    var last = (_b = lastName === null || lastName === void 0 ? void 0 : lastName.charAt(0)) !== null && _b !== void 0 ? _b : "";
    if (first.length > 0 && last.length > 0) {
        return "".concat(first).concat(last);
    }
    else {
        return null;
    }
};
