import * as codec from "./codec";
import * as decoder from "./decoder";
import * as encoder from "./encoder";
/**
 * Defines how to decode department information for users returned from `/api/v1/users` endpoints.
 */
var DepartmentCodec = codec.object({
    code: codec.optional(codec.string),
    name: codec.optional(codec.string)
}, codec.identity, codec.identity);
/**
 * Defines how to decode user information from either `/api/v0/users` or `/api/v1/users` endpoints.
 */
export var UserDecoder = decoder.object({
    id: decoder.string.as(),
    first_name: decoder.optional(decoder.string),
    last_name: decoder.optional(decoder.string),
    department: decoder.optional(decoder.string.orElse(DepartmentCodec)),
    department_name: decoder.optional(decoder.string),
    email: decoder.optional(decoder.string)
}, function (obj) {
    var _a;
    var departmentCode = null;
    var departmentName = obj.department_name;
    if (typeof obj.department === 'string') {
        departmentCode = obj.department;
        departmentName = obj.department_name;
    }
    else if (obj.department !== null) {
        departmentCode = obj.department.code;
        departmentName = (_a = obj.department.name) !== null && _a !== void 0 ? _a : obj.department_name;
    }
    return {
        id: obj.id,
        firstName: obj.first_name,
        lastName: obj.last_name,
        department: departmentCode,
        departmentName: departmentName,
        email: obj.email
    };
});
/**
 * Defines how to encode user information.
 */
var UserEncoder = encoder.object({
    id: encoder.string.as(),
    first_name: encoder.optional(encoder.string),
    last_name: encoder.optional(encoder.string),
    department: encoder.optional(encoder.string),
    department_name: encoder.optional(encoder.string),
    email: encoder.optional(encoder.string)
}, function (obj) {
    return {
        id: obj.id,
        first_name: obj.firstName,
        last_name: obj.lastName,
        department: obj.department,
        department_name: obj.departmentName,
        email: obj.email
    };
});
/**
 * Defines how to decode and encode user models.
 */
export var UserCodec = codec.instance(UserDecoder, UserEncoder);
