/*
 * (c) Copyright 2023, Panavion GmbH.
 */
/**
 * Hacky workaround that translate colors from their Tailwind names into RGB color codes.
 */
export var translateColor = function (str) {
    if (!str) {
        return null;
    }
    switch (str) {
        case "green-100":
            return "#dcfce7";
        case "red-100":
            return "#fee2e2";
        case "yellow-100":
            return "#fef9c3";
        default:
            return str;
    }
};
