import * as decoder from "./decoder";
import { DecodingError } from "./decoder";
import * as encoder from "./encoder";
var Codec = /** @class */ (function () {
    // ------------------------------------------ Constructors
    function Codec(decoder, encoder) {
        this.decoder = decoder;
        this.encoder = encoder;
    }
    // ------------------------------------------ Decodable methods
    Codec.prototype.decode = function (json, context) {
        if (context === void 0) { context = []; }
        return this.decoder.decode(json, context);
    };
    // ------------------------------------------ Encodable methdos
    Codec.prototype.encode = function (value) {
        return this.encoder.encode(value);
    };
    // ------------------------------------------ Transformations
    Codec.prototype.as = function () {
        return this.map(function (value) { return value; }, function (value) { return value; });
    };
    Codec.prototype.asDecoder = function () {
        return new decoder.Decoder(this.decoder);
    };
    Codec.prototype.map = function (decode, encode) {
        var _this = this;
        return new Codec(decoder.instance(function (json, context) {
            return decode(_this.decode(json, context));
        }), encoder.instance(function (value) { return _this.encode(encode(value)); }));
    };
    Codec.prototype.emap = function (decode, encode) {
        var _this = this;
        return new Codec(decoder.instance(function (json, context) {
            return decode(_this.decode(json, context)).fold(function (error) {
                throw new DecodingError(error, json, context);
            }, function (value) { return value; });
        }), encoder.instance(function (value) { return _this.encode(encode(value)); }));
    };
    return Codec;
}());
export { Codec };
// ---------------------------------------------- Primitive codecs
/**
 * Codec implementation for booleans.
 */
export var boolean = new Codec(decoder.boolean, encoder.boolean);
/**
 * Codec implementation for numbers.
 */
export var number = new Codec(decoder.number, encoder.number);
/**
 * Codec implementation for strings.
 */
export var string = new Codec(decoder.string, encoder.string);
/**
 * Codec implementation for dates.
 */
export var date = new Codec(decoder.date, encoder.date);
/**
 * Codec implementation for date times.
 */
export var dateTime = new Codec(decoder.dateTime, encoder.dateTime);
/**
 * Codec implementation for UUIDs.
 */
export var uuid = new Codec(decoder.uuid, encoder.uuid);
// ---------------------------------------------- Compound codecs
export function array(codec) {
    return new Codec(decoder.array(codec), encoder.array(codec));
}
export function optional(codec) {
    return new Codec(decoder.optional(codec), encoder.optional(codec));
}
// ---------------------------------------------- Object methods
export function object(schema, decode, encode) {
    return new Codec(decoder.object(schema, decode), encoder.object(schema, encode));
}
// ---------------------------------------------- Utility methods
export { camelCase, snakeCase } from "./casing";
export { DecodingError } from "./decoder";
export * as decoder from "./decoder";
export * as encoder from "./encoder";
export { identity } from "./decoder";
export function instance(decode, encode) {
    return new Codec(decode, encode);
}
