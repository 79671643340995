import { codec, UserCodec } from "@police-mobile/common-json";
export var AuthenticationCodec = codec.object({
    created_at: codec.dateTime,
    expires_at: codec.dateTime,
    user: UserCodec,
}, function (obj) {
    return {
        createdAt: obj.created_at,
        expiresAt: obj.expires_at,
        user: obj.user
    };
}, function (obj) {
    return {
        created_at: obj.createdAt,
        expires_at: obj.expiresAt,
        user: obj.user
    };
});
